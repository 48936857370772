import { uniqWith } from 'lodash-es';
import { ApprovalDTO, NodeId, Node } from '../../../../serverapi/api';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import { TApprovalTableData, TLastSortedColumnStatus, TSortStatus } from '../approvalsTable.types';

const STATUS_SORT_ORDER = {
    APPROVED: 0,
    NOT_APPROVED: 1,
    CANCELLED: 2,
    DRAFT: 3,
    IN_PROCESS: 4,
};

export const getUniqNodeIdsFromApprovals = (approvals: ApprovalDTO[]): NodeId[] => {
    const nodeIds: NodeId[] = approvals
        .map((approval) => approval.elementIds.map((elementId) => ({ ...approval.id, id: elementId })))
        .flat();
    return uniqWith(nodeIds, compareNodeIds) as NodeId[];
};

export const getElementNodes = (nodes: Node[], elementIds: string[], nodeId: NodeId): Node[] => {
    return nodes.filter((node) =>
        elementIds.find((elementId) => compareNodeIds(node.nodeId, { ...nodeId, id: elementId })),
    );
};

export const sortApprovals = (
    approvalTableData: TApprovalTableData[],
    sortStatus: TSortStatus,
    lastSortedColumnStatus: TLastSortedColumnStatus,
) => {
    if (!lastSortedColumnStatus) return approvalTableData;

    const sortByColumn = {
        createdAt: (data: TApprovalTableData[]) =>
            sortStatus.createdAt === 'asc'
                ? data.sort((a, b) => a.createdAt! - b.createdAt!)
                : data.sort((a, b) => b.createdAt! - a.createdAt!),
        status: (data: TApprovalTableData[]) =>
            sortStatus.status === 'asc'
                ? data.sort((a, b) => STATUS_SORT_ORDER[a.status!] - STATUS_SORT_ORDER[b.status!])
                : data.sort((a, b) => STATUS_SORT_ORDER[b.status!] - STATUS_SORT_ORDER[a.status!]),
        name: (data: TApprovalTableData[]) =>
            sortStatus.name === 'asc'
                ? data.sort((a, b) => a.name!.localeCompare(b.name!))
                : data.sort((a, b) => b.name!.localeCompare(a.name!)),
        createdBy: (data: TApprovalTableData[]) =>
            sortStatus.createdBy === 'asc'
                ? data.sort((a, b) => a.createdBy!.localeCompare(b.createdBy!))
                : data.sort((a, b) => b.createdBy!.localeCompare(a.createdBy!)),
        description: (data: TApprovalTableData[]) => {
            console.log('sdfsdfsd', sortStatus, data)
            return sortStatus.description === 'asc'
                ? data.sort((a, b) => (a.description || '').localeCompare(b.description || ''))
                : data.sort((a, b) => (b.description || '').localeCompare(a.description || ''));
        },
    };

    return sortByColumn[lastSortedColumnStatus](approvalTableData.slice());
};
